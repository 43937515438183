.project-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .project-cards {
    flex-direction: column;
  }
}
