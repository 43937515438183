.timeline-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item-text {
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.item-text h3 {
  font-size: 14px;
  padding: 4px;
  /* color: #f9f871; */
}

.item-text h4 {
  font-size: 16px;
  /* color: #7ed57b; */
}

@media (max-width: 768px) {
  .timeline-container {
    flex-direction: column;
  }

  .item {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 8px;
  }
}
