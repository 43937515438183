body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

@media (max-width: 768px) {
  .container {
    width: 90vw;
  }
}
