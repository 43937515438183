* {
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}

.App {
  background-color: #051937;
}

.span-color {
  color: #7ed57b;
}
