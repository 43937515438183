.section {
  display: flex;
  flex-direction: column;
  align-items: left;
  /* height: 10vh; */
}

.section-header {
  margin-top: 65px;
  margin-bottom: 15px;
  font-size: 30px;
}

.section-content {
  font-size: 16px;
  margin-bottom: 20px;
  white-space: pre-line;
  vertical-align: bottom;
}
